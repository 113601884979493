export const useDownloadFiles = (fileUrl: string, fileName: string, options?: RequestInit) => {
  fetch(fileUrl, options)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      throw new Error('Error fetching the file:', error);
    });
};
