import { BarChartProps, LineChartProps } from '@clarke-energia/foton-v2';

import { colors } from '@utils/theme';

import { Contract, PriceChartData } from '@hooks/get-contracts/types';

// TODO: this unification should be done by id
const unifyContracts = (contracts: Contract[]): Contract[] => {
  const unified: Contract[] = [];
  contracts.forEach((contract) => {
    const contractCopy: Contract = JSON.parse(JSON.stringify(contract));
    const existentContract = unified.find((unicated) => unicated.contracted === contract.contracted);
    if (!existentContract) {
      unified.push(contractCopy);
      return;
    }

    existentContract.unitsNames = Array.from(new Set([...existentContract.unitsNames, ...contract.unitsNames]));
    const volumePerYear: Contract['volumePerYear'] = [];
    if (existentContract.volumePerYear?.length) {
      existentContract.volumePerYear.concat(contractCopy.volumePerYear || []).forEach((volume) => {
        const existentVolume = volumePerYear.find((v) => v.year === volume.year);
        if (!existentVolume) {
          volumePerYear.push(volume);
          return;
        }
        existentVolume.amountMWm += volume.amountMWm;
        existentVolume.amountMWh += volume.amountMWh;
      });
    }
    existentContract.volumePerYear = volumePerYear;

    const prices: Contract['prices'] = [];
    if (existentContract.prices?.length) {
      existentContract.prices.concat(contractCopy.prices || []).forEach((price) => {
        const existentPrice = prices.find((p) => p.year === price.year);
        if (!existentPrice) {
          prices.push(price);
          return;
        }
        existentPrice.amount += price.amount;
        existentPrice.rawAmount += price.rawAmount;
      });
    }
    existentContract.prices = prices;
  });
  return unified;
};

export const volumeChartData = (input: Contract[], unit: 'MWM' | 'MWH' = 'MWM'): BarChartProps => {
  const allYearsSet = new Set<number>();
  const series = [] as BarChartProps['series'];

  const contracts = unifyContracts(input);
  contracts.forEach((item) => {
    item.volumePerYear?.forEach((v) => allYearsSet.add(v.year));
  });

  const allYears = Array.from(allYearsSet).sort((a, b) => a - b);
  const labels = allYears.map((year) => `${year}`);

  contracts.forEach((item, idx) => {
    const yearToAmountMap = new Map<number, number>();
    item.volumePerYear?.forEach((v) => {
      yearToAmountMap.set(v.year, unit === 'MWM' ? v.amountMWm : v.amountMWh);
    });

    const data = allYears.map((year) => yearToAmountMap.get(year) ?? 0);

    series.push({
      name: item.contracted + (unit === 'MWM' ? ' (MW-m)' : ' (MWh)'),
      data: data,
      color: idx === 0 ? colors.primary[1] : colors.primary[2],
    });
  });

  return { labels, series };
};

export const priceChartDataFormatted = (input: Contract[]): PriceChartData => {
  const allYearsSet = new Set<number>();
  const series = [] as LineChartProps['series'];

  const contracts = unifyContracts(input);
  contracts.forEach((item) => {
    item.prices?.forEach((v) => allYearsSet.add(v.year));
  });

  const allYears = Array.from(allYearsSet).sort((a, b) => a - b);
  const labels = allYears.map((year) => `${year}`);

  contracts.forEach((item, idx) => {
    const yearToAmountMap = new Map<number, number>();
    item.prices?.forEach((v) => {
      yearToAmountMap.set(v.year, v.rawAmount);
    });

    const data = allYears.map((year) => yearToAmountMap.get(year) ?? 0);

    series.push({
      name: `Preço ${item.contracted} (R$/MWh)`,
      values: data,
      color: idx === 0 ? colors.primary[1] : colors.primary[2],
    });
  });

  return { labels, series };
};
