export enum MigrationState {
  OVERDUE = 'OVERDUE',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export interface IMigrationReport {
  id: string;
  thirdPartyId: string;
  commercialGroup: string;
  conclusionPercentage: number;
  units: IUnitsReport[];
}

export interface IUnitsReport {
  thirdPartyId: string;
  nickname: string;
  conclusionPercentage: number;
  topics: ITopic[];
  dueDate: string | null;
  status: MigrationState;
}

export interface ITopic {
  thirdPartyId: string;
  title: string;
  conclusionPercentage: number;
  dueDate: string | null;
  tasks: ITask[];
}

export interface ITask {
  thirdPartyId: string;
  title: string;
  dueDate: string | null;
  status: TaskStatus | null;
  customerResponsibility: string;
  responsible: IResponsible;
  description: string;
  taskType: TaskType;
}

export interface IResponsible {
  category: Category;
  name: string;
}

export enum Category {
  CLARKE = 'CLARKE',
  CUSTOMER = 'CUSTOMER',
  TRADER = 'TRADER',
}

export enum TaskStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  OVERDUE = 'OVERDUE',
}

export enum TaskType {
  SOCIAL_CONTRACT = 'SOCIAL_CONTRACT',
  LEGAL_REP_ID = 'LEGAL_REP_ID',
  FINANCIAL_BALANCE = 'FINANCIAL_BALANCE',
  DISTRIBUTOR_LOGIN = 'DISTRIBUTOR_LOGIN',
  PRODUCT_MODELING = 'PRODUCT_MODELING',
  REQUEST_QUOTE = 'REQUEST_QUOTE',
  ENERGY_SUPPLIER_TERM = 'ENERGY_SUPPLIER_TERM',
  CCEAL_SIGNATURE = 'CCEAL_SIGNATURE',
  VIGENCY_DATE = 'VIGENCY_DATE',
  CONTRACT_TERMINATION = 'CONTRACT_TERMINATION',
  DENUNCIATION_LETTER = 'DENUNCIATION_LETTER',
  SEND_DENUNCIATION = 'SEND_DENUNCIATION',
  CONFIRM_DENUNCIATION_RECEIPT = 'CONFIRM_DENUNCIATION_RECEIPT',
  MEASUREMENT_INSPECTION = 'MEASUREMENT_INSPECTION',
  SMF_ADJUSTMENT_QUOTE = 'SMF_ADJUSTMENT_QUOTE',
  ENSURE_SMF_ADJUSTMENT = 'ENSURE_SMF_ADJUSTMENT',
  SUPPLIER_BUDGET = 'SUPPLIER_BUDGET',
  ENSURE_SUPPLIER_CONTRACT = 'ENSURE_SUPPLIER_CONTRACT',
  COMMISSIONING_REPORT_APPROVAL = 'COMMISSIONING_REPORT_APPROVAL',
  MEASUREMENT_POINT_REGISTRATION = 'MEASUREMENT_POINT_REGISTRATION',
  SEND_DHC = 'SEND_DHC',
  MAPPED_MEASUREMENT_POINT = 'MAPPED_MEASUREMENT_POINT',
  CCV_SIGNATURE = 'CCV_SIGNATURE',
  NO_MAPPED = 'NO_MAPPED',
  CLIENT_PACT_SIGNATURE = 'CLIENT_PACT_SIGNATURE',
  CLIENT_CUSD_SIGNATURE = 'CLIENT_CUSD_SIGNATURE',
}
