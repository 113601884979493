import { ReactNode, useRef } from 'react';
import { Carousel } from '@clarke-energia/foton-v2';

import StepProgressCard, { StepProgressCardProps } from '@components/molecules/step-progress-card';
import MultipleCardsContainer from '@components/atoms/layouts/multiple-cards-container';
import { ITopic, MigrationState } from '@hooks/migration-report/types';
import { useIsMobile } from '@hooks/is-mobile';
import { getMigrationState } from '@pages/migration-reports/helper';

import style from './style.module.css';

const mapStepStatus = {
  [MigrationState.IN_PROGRESS]: 'ongoing',
  [MigrationState.DONE]: 'success',
  [MigrationState.OVERDUE]: 'warning',
  neutral: 'neutral',
};

const getStepStatus = (conclusionPercentage: number, dueDate: string | null): StepProgressCardProps['status'] => {
  const state = getMigrationState(conclusionPercentage, dueDate);
  if (state === MigrationState.IN_PROGRESS && conclusionPercentage === 0) {
    // @ts-expect-error - Is correct mapping
    return mapStepStatus.neutral;
  }
  // @ts-expect-error - Is correct mapping
  return mapStepStatus[state];
};

const CarouselMobileHandler = ({
  children,
  controlSizeRef,
}: {
  children: ReactNode[];
  controlSizeRef: React.RefObject<HTMLDivElement>;
}) => {
  const isMobile = useIsMobile();
  const size: number = controlSizeRef?.current?.offsetWidth as number;

  if (isMobile) {
    return (
      <div
        style={{
          width: `${size - 10}px`,
        }}
      >
        {/* 188 is max card size (vide style) */}
        <Carousel slidesToShow={parseFloat((size / (188 + 16)).toFixed(4))} useArrows>
          {children}
        </Carousel>
      </div>
    );
  }

  return (
    <MultipleCardsContainer childMinWidth={188} gap={32}>
      {children}
    </MultipleCardsContainer>
  );
};

interface StepsProgressHeaderProps {
  topics: ITopic[];
  selectedTopicId?: string;
  onCardClick?: (topicId: string) => void;
}

const StepsProgressHeader = (props: StepsProgressHeaderProps) => {
  const controlSizeRef = useRef<HTMLDivElement>(null);

  return (
    <div className={style.container}>
      <div className="w-full" ref={controlSizeRef}>
        {controlSizeRef?.current && (
          <CarouselMobileHandler controlSizeRef={controlSizeRef}>
            {props.topics.map((topic, index) => {
              return (
                <div
                  key={topic.thirdPartyId}
                  onClick={() => props.onCardClick?.(topic.thirdPartyId)}
                  className={style.stepContainer}
                >
                  <div>
                    <StepProgressCard
                      step={index + 1}
                      description={topic.title}
                      progress={topic.conclusionPercentage * 100}
                      status={getStepStatus(topic.conclusionPercentage, topic.dueDate)}
                      focus={props.selectedTopicId === topic.thirdPartyId}
                    />
                  </div>
                </div>
              );
            })}
          </CarouselMobileHandler>
        )}
      </div>
    </div>
  );
};

export default StepsProgressHeader;
