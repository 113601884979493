import React, { HTMLAttributes } from 'react';
import { Card, IconButton } from '@clarke-energia/foton-v2';

import { UnitSummaryEconomyReport } from '@pages/economy-reports/types';

import { useIsMobile } from '@hooks/is-mobile';
import { useScreenSize } from '@hooks/screen-size';

import { getVariant, splitUnitsAsChunks } from './functions';
import style from './style.module.css';

export interface UnitEconomyCardsProps extends HTMLAttributes<HTMLDivElement> {
  unitEconomyCardsItems: UnitSummaryEconomyReport[];
  onIconClick: (id: string) => void;
}

const UnitEconomyCards: React.FC<UnitEconomyCardsProps> = ({ unitEconomyCardsItems, onIconClick, ...props }) => {
  const unitsChunks = splitUnitsAsChunks(unitEconomyCardsItems);

  const isMobile = useIsMobile();
  const { width } = useScreenSize();

  const variant = getVariant(isMobile, width);

  return (
    <div {...props}>
      {unitsChunks.map((units, index) => {
        return (
          <div className={style.cardsContainer} key={`cards-container-${index}`}>
            {units.map(({ unit, economy, energy }) => {
              return (
                <Card.Root key={unit.name} className={style.cardRoot}>
                  <Card.Header className={style.cardHeader}>
                    <Card.Title title={unit.name} />
                    <IconButton icon="EyeIcon" onClick={() => onIconClick(unit.id)} />
                  </Card.Header>
                  <Card.Content>
                    <Card.InfoList direction="row" shouldWrap={true} className={style.cardList}>
                      <Card.Info
                        className={style.cardInfo}
                        type="primary"
                        title="Economia"
                        message={economy}
                        icon={{
                          icon: 'CurrencyDollarIcon',
                          size: 'sm',
                        }}
                        variant={variant}
                      />
                      <Card.Info
                        className={style.cardInfo}
                        type="primary"
                        title="Custo"
                        message={energy.cost}
                        icon={{
                          icon: 'BanknotesIcon',
                          size: 'sm',
                        }}
                        variant={variant}
                      />
                      <Card.Info
                        className={style.cardInfo}
                        type="primary"
                        title="Consumo"
                        message={energy.consumption}
                        icon={{
                          icon: 'LightBulbIcon',
                          size: 'sm',
                        }}
                        variant={variant}
                      />
                    </Card.InfoList>
                  </Card.Content>
                </Card.Root>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default UnitEconomyCards;
