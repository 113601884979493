export const LOGIN_PATH = '/login';

// Private paths
export const HOME_PATH = '/';
export const UNIT_PATH = '/unidade';
export const ENERGY_PRICE = '/cotacao';
export const CONTRACTS_PATH = '/contratos';
export const MIGRATION_REPORT_PATH = '/migracao';
export const CONSUMPTION_DASHBOARD_PATH = '/consumo';
export const NOTIFICATION_PATH = '?notification=open';
export const SUSTAINABILITY_PATH = '/sustentabilidade';
export const ATTENDANCE_PATH = '/falar-com-especialista';
export const ECONOMY_REPORT_PATH = '/relatorio-economia';

// Public paths
// 404
export const DOES_NOT_EXIST_PATH = '/does-not-exist';

export const PUBLIC_PATHS_LIST = [];
