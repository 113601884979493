import { gql } from '@apollo/client';

export const QUERY_GET_CONSUMPTION_FROM_ECONOMY_REPORT_PER_GROUP = gql`
  query GetGroupReports($groupId: ID!) {
    getAllGroupsEconomyReportByGroupID(id: $groupId) {
      date
      totalMonthlyEnergyConsumption
      group {
        unitsResume {
          totalContractedDemands
        }
      }
    }
  }
`;

export const QUERY_GET_CONSUMPTIONS_FROM_UNIT_ECONOMY_REPORT_BY_GROUP_ID = gql`
  query GetUnitsReportsByGroup($groupId: ID!) {
    unitsEconomyReportsByGroupId(id: $groupId) {
      date
      energyTotalConsumption
      unit {
        group {
          unitsResume {
            totalContractedDemands
          }
        }
      }
    }
  }
`;
