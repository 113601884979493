import { Accordion } from '@clarke-energia/foton-v2';
import NuggetTagHeader, {
  NuggetTagHeaderProps,
} from '@components/organisms/migrations/migration-document-state-accordion/nugget-tag-header';
import UnitMigrationDocumentInfos, {
  UnitMigrationDocumentInfosProps,
} from '@components/organisms/migrations/migration-document-state-accordion/unit-migration-document-infos';

interface DocumentStateAccordionProps {
  header: NuggetTagHeaderProps;
  content: UnitMigrationDocumentInfosProps;
}

const DocumentStateAccordion: React.FC<DocumentStateAccordionProps> = (props: DocumentStateAccordionProps) => {
  return (
    <div className="bg-transparent">
      <Accordion
        header={<NuggetTagHeader {...props.header} />}
        content={<UnitMigrationDocumentInfos {...props.content} />}
      />
    </div>
  );
};

export default DocumentStateAccordion;
