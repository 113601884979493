import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@clarke-energia/foton';

import { IUnitsReport } from '@hooks/migration-report/types';
import { InformationMessagesByMigrationMoment } from '@utils/constants/common';
import { getConcludedMigrationUnits } from '@pages/migration-reports/helper';
import { ATTENDANCE_PATH, MIGRATION_REPORT_PATH } from '@routers/constants';

export interface IMessageComponent {
  title: string;
  description: string;
  mainButtonLabel: string;
  mainButtonAction?: () => void;
}

export const MessageComponent = ({ title, description, mainButtonLabel, mainButtonAction }: IMessageComponent) => {
  return (
    <div className="flex flex-col col-span-8 gap-5 h-full">
      <div className="text-heading-2xlarge">{title}</div>
      <div className="text-paragraph-medium">{description}</div>
      <Button kind="primary" label={mainButtonLabel} className="mt-8 w-fit" onClick={mainButtonAction} />
    </div>
  );
};

export const GenerateMessageByMigrationMoment = ({
  migrationReportByUnits,
}: {
  migrationReportByUnits: IUnitsReport[];
}) => {
  const navigate = useNavigate();

  if (getConcludedMigrationUnits(migrationReportByUnits))
    return (
      <MessageComponent
        title={InformationMessagesByMigrationMoment.MIGRATION_CONCLUDED.title}
        description={InformationMessagesByMigrationMoment.MIGRATION_CONCLUDED.description}
        mainButtonLabel={InformationMessagesByMigrationMoment.MIGRATION_CONCLUDED.mainButtonLabel}
        mainButtonAction={() => navigate(ATTENDANCE_PATH)}
      />
    );
  else
    return (
      <MessageComponent
        title={InformationMessagesByMigrationMoment.MIGRATION_IN_PROGRESS.title}
        description={InformationMessagesByMigrationMoment.MIGRATION_IN_PROGRESS.description}
        mainButtonLabel={InformationMessagesByMigrationMoment.MIGRATION_IN_PROGRESS.mainButtonLabel}
        mainButtonAction={() => navigate(MIGRATION_REPORT_PATH)}
      />
    );
};

export default MessageComponent;
