import { useAuth } from '@src/ApolloWrapper';

export const useAuthorizationHeaders = () => {
  const {
    authStatus: { accessToken },
  } = useAuth();
  const headersWithAuthorization: RequestInit = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return headersWithAuthorization;
};
