import { useLazyQuery } from '@apollo/client';

import { GET_CONTRACTS_BY_GROUP } from './queries';
import { Contract, ContractGroupGraphQL } from './types';
import { formatEnergyContracts, formatManagementContracts } from './parser';

export const useContractByGroup = () => {
  const [getContractByGroup, { data, loading, error }] = useLazyQuery<ContractGroupGraphQL>(GET_CONTRACTS_BY_GROUP);
  let formattedData: { energyContracts: Contract[]; managementContracts: Contract[] } = {
    energyContracts: [],
    managementContracts: [],
  };

  if (data) {
    const {
      group: { clarkeContracts, energyContracts },
    } = data;

    const energyContractsFormatted = energyContracts.length ? formatEnergyContracts(energyContracts) : [];
    const managementeContractsFormatted = clarkeContracts.length ? formatManagementContracts(clarkeContracts) : [];

    formattedData = {
      energyContracts: energyContractsFormatted,
      managementContracts: managementeContractsFormatted,
    };
  }

  return {
    getContractByGroup,
    singleUnit: data?.group.units.length === 1,
    data: formattedData,
    loading,
    error,
  };
};
