import { formatCurrency } from '@utils/text';

import { UnitEconomyReportDataResponse, UnitReportParsed } from '@hooks/economy/types';

import { EconomyChartData } from '../types';
import { getRatio, getReportById } from '../functions';

export const buildEconomyChartDataFromUnitEconomyReport = (
  reportByUnitPeriod: UnitEconomyReportDataResponse,
): EconomyChartData => {
  return {
    freeMarket: formatCurrency(reportByUnitPeriod?.freeMarket.totalValue || 0),
    conventionalMarket: formatCurrency(reportByUnitPeriod?.conventionalMarket.totalValue || 0),
    economy: getRatio(
      reportByUnitPeriod?.freeMarket.totalValue || 0,
      reportByUnitPeriod?.conventionalMarket.totalValue || 0,
    ),
  };
};

export const buildEconomyChartDataFromUnitReports = (
  unitsReports: UnitReportParsed[],
  reports: UnitEconomyReportDataResponse[],
): EconomyChartData => {
  const data = {
    freeMarket: 0,
    conventionalMarket: 0,
    economy: 0,
  };

  unitsReports.forEach((unitReport) => {
    const report = getReportById(unitReport.reportId, reports);
    if (report) {
      data.freeMarket += report.freeMarket.totalValue;
      data.conventionalMarket += report.conventionalMarket.totalValue;
    }
  });

  data.economy = getRatio(data.freeMarket, data.conventionalMarket);

  return {
    freeMarket: formatCurrency(data.freeMarket),
    conventionalMarket: formatCurrency(data.conventionalMarket),
    economy: data.economy,
  };
};

export const buildEconomyChartDataFromUnitId = (
  unitId: string,
  reports: UnitEconomyReportDataResponse[],
): EconomyChartData => {
  const data = {
    freeMarket: 0,
    conventionalMarket: 0,
    economy: 0,
  };

  reports
    .filter((report) => {
      return report.unit.id == unitId;
    })
    .forEach((report) => {
      data.freeMarket += report.freeMarket.totalValue;
      data.conventionalMarket += report.conventionalMarket.totalValue;
    });

  data.economy = getRatio(data.freeMarket, data.conventionalMarket);
  return {
    freeMarket: formatCurrency(data.freeMarket),
    conventionalMarket: formatCurrency(data.conventionalMarket),
    economy: data.economy,
  };
};

export const buildEconomyChartDataFromAllHistory = (reports: UnitEconomyReportDataResponse[]) => {
  const data = {
    freeMarket: 0,
    conventionalMarket: 0,
    economy: 0,
  };
  reports.forEach((report) => {
    data.freeMarket += report.freeMarket.totalValue;
    data.conventionalMarket += report.conventionalMarket.totalValue;
  });
  data.economy = getRatio(data.freeMarket, data.conventionalMarket);
  return {
    freeMarket: formatCurrency(data.freeMarket),
    conventionalMarket: formatCurrency(data.conventionalMarket),
    economy: data.economy,
  };
};
