import React from 'react';

import { HeroIcon, Select } from '@clarke-energia/foton-v2';

import { populateOptions } from '../default/functions';
import { ExportSelectProps, OptionsMap } from '../default/types';

const exportsMap: OptionsMap = {
  png: {
    label: 'Exportar como PNG',
    value: 'PNG',
  },
  excel: {
    label: 'Exportar como Excel',
    value: 'EXCEL',
  },
};

const ExportSelectIcon: React.FC<ExportSelectProps> = ({
  onChange,
  availableExports = {
    png: true,
    excel: true,
  },
}) => {
  const options = populateOptions(availableExports, exportsMap);

  return (
    <Select.Root>
      <Select.Input
        appendIcon={null}
        options={options}
        onChange={(val) => {
          onChange?.(val);
        }}
        value={''}
        placeholder={<HeroIcon icon="EllipsisVerticalIcon" extraClassNames="size-5" />}
      />
    </Select.Root>
  );
};

export default ExportSelectIcon;
