import { IconName } from '@clarke-energia/foton-v2';
import { twMerge } from 'tailwind-merge';

import { ITask } from '@hooks/migration-report/types';
import { formatDateFromString } from '@utils/dayjs';
import { capitalizePhrases, capitalizeWords, upperFirstLetter } from '@utils/text';

import DocumentStateAccordion from '../migration-document-state-accordion';
import { taskTypeMapIcon } from './helpers';

import style from './style.module.css';

const taskStatusMap: Record<
  // @ts-expect-error - ignore when status is null
  ITask['status'],
  {
    icon: IconName;
    label: string;
    iconClass: string;
    tagClass: string;
  }
> = {
  TO_DO: {
    icon: 'ClockIcon',
    label: 'Pendente',
    iconClass: 'bg-gray-10 text-gray-60',
    tagClass: 'bg-[#FFF6E0] text-[#E0A000]',
  },
  IN_PROGRESS: {
    icon: 'ClockIcon',
    label: 'Em andamento',
    iconClass: 'bg-gray-10 text-gray-60',
    tagClass: 'bg-[#FFF6E0] text-[#E0A000]',
  },
  DONE: {
    icon: 'CheckCircleIcon',
    label: 'Concluído',
    iconClass: 'bg-primary-10 text-primary-60',
    tagClass: 'bg-primary-10 text-primary-70',
  },
  OVERDUE: {
    icon: 'ClockIcon',
    label: 'Atrasado',
    iconClass: 'bg-orange-20 text-[#E5391A]',
    tagClass: 'bg-orange-10 text-orange-30',
  },
};

interface MigrationTasksProps {
  tasks: ITask[];
}

const MigrationTasks = (props: MigrationTasksProps) => {
  return (
    <div className="flex flex-col">
      {props.tasks.map((task, index) => {
        const taskMap = taskStatusMap[task.status || 'TO_DO'];
        return (
          <div key={index} className={style.documentStateContainer}>
            <DocumentStateAccordion
              key={index}
              content={{
                // is really static, because we dont have this information (yet)
                documents: 1,
                pendencies: task.status === 'DONE' ? 0 : 1,
                expectedDate: task.dueDate
                  ? formatDateFromString(task.dueDate, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY')
                  : 'Não previsto',
                responsible: task.responsible?.name ? capitalizeWords(task.responsible?.name) : 'Não atribuído',
                document: {
                  description: capitalizePhrases(task.description),
                  names: upperFirstLetter(task.title),
                },
              }}
              header={{
                title: upperFirstLetter(task.title),
                icon: {
                  icon: taskTypeMapIcon[task.taskType] || taskTypeMapIcon.NO_MAPPED,
                  extraClassNames: taskMap.iconClass,
                },
                tag: {
                  icon: task.status ? taskMap.icon : 'ClockIcon',
                  label: task.status ? taskMap.label : 'Pendente',
                  className: twMerge(taskMap.tagClass, 'text-[10px]'),
                },
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MigrationTasks;
