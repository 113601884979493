import { PopUpList, PopUpListProps } from '@clarke-energia/foton';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '@components/templates/screen-layout';
import MessageComponent from '@components/molecules/general/message-component';
import PageSectionGenerator from '@components/molecules/general/page-section-generator';
import { ConsumptionSkeleton } from '@components/molecules/skeleton/consumption-skeleton';
import { ConsumptionCompilationContent } from '@components/organism/consumption-compilation';
import {
  DatetimeMeasurement,
  DatetimeMeasurementStatus,
  GroupDatetimeMeasurement,
  IDateProjection,
  MonthlyControlMeasurementByUnitId,
  MonthlyGroupControlMeasurement,
  Units,
} from '@contexts/consumption';
import { useConsumption } from '@hooks/use-consumption';
import { useUserInfo } from '@hooks/use-user-info';
import { CONSUMPTION_DASHBOARD_PATH, HOME_PATH, MIGRATION_REPORT_PATH } from '@routers/constants';
import { InformationMessagesByConsumptionMoment } from '@utils/constants/common';
import { formatStringDate } from '@utils/text';
import { CLARKE_CUSTOMER_GROUP_ID, ClarkeCustomerConsumptionPeriods } from './consumption-static-data';
import {
  autoFillConsumptionProjection,
  dateMeasurement,
  getActualMonth,
  getMonthYear,
  getMonthYearLabel,
  includeCurrentMonthOnFirstDay,
  lastDateWithMeasurement,
  triggerButtonEvent,
} from './helper';

const ConsumptionHub: React.FC = () => {
  const navigate = useNavigate();
  const { user, isFetchingUser } = useUserInfo();
  const {
    monthlyControlMeasurementByUnitId,
    monthlyGroupControlMeasurement,
    consumptionProjectionByUnit,
    consumptionProjectionByGroup,
    groupConsolidatedMeasurementById,
    setConsolidatedMeasurementDocId,
    setGroupConsolidatedMeasurementDocId,
    consolidatedMeasurementById,
    totalMonthlyConsumptionByUnit,
    totalMonthlyConsumptionByGroup,
    setGroupId,
    unitId,
    setUnitId,
    setMonthYear,
    monthYear,
    units,
    loading,
  } = useConsumption();

  const [labelName, setLabelName] = useState<string>('');
  const [labelDate, setLabelDate] = useState<string>('');
  const [labelMonthYear, setLabelMonthYear] = useState<string>('');
  const [measurementPeriod, setMeasurementPeriod] = useState<string | undefined>('-');
  const [openConsumptionByUnitSelector, setOpenConsumptionByUnitSelector] = useState<boolean>(false);
  const [consumptionByUnit, setConsumptionByUnit] = useState<PopUpListProps['items']>();
  const [barChartLabels, setBarChartLabels] = useState<Array<string> | undefined>([]);
  const [barChartValues, setBarChartValues] = useState<Array<number | null>>([]);
  const [barChartSecondValues, setBarChartSecondValues] = useState<Array<number> | undefined>([]);
  const [barChartSecondLabels, setBarChartSecondLabels] = useState<Array<string> | undefined>([]);
  const [lineChartLabels, setLineChartLabels] = useState<Array<string | null>>([]);
  const [lineChartValues, setLineChartValues] = useState<Array<number | null>>([]);
  const [controlDocsLength, setControlDocsLength] = useState<number>(0);
  const [openPeriodSelector, setOpenPeriodSelector] = useState<boolean>(false);
  const [datesWithValues, setDatesWithValues] = useState<Array<string>>([]);
  const [consumptionPeriods, setConsumptionPeriods] = useState<PopUpListProps['items']>();
  const exportRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  function ConsumptionPageRenderHandler<T extends MonthlyControlMeasurementByUnitId | MonthlyGroupControlMeasurement>(
    monthlyControlMeasurement: T[],
  ) {
    const lastMeasurement = lastDateWithMeasurement(monthlyControlMeasurement);
    setBarChartLabels(createBarChartLabels(monthlyControlMeasurement));
    setBarChartValues(createBarChartValuesInKwh(monthlyControlMeasurement));
    setControlDocsLength(monthlyControlMeasurement.length);

    return lastMeasurement;
  }

  const generateConsumptionByUnitPopUpList = (units: Units[]) => {
    if (units.length > 1) {
      const consumptionByUnit = units?.map((unit) => ({
        label: unit.name,
        onClick: () => {
          setOpenConsumptionByUnitSelector(false);
          setUnitId(unit.id);
          setLabelName(unit.name);
          triggerButtonEvent(unit.name);
        },
      }));

      const generatePopupListIfGroupsHasMoreThanOneUnit = () => {
        return [
          {
            label: 'Geral',
            onClick: () => {
              setOpenConsumptionByUnitSelector(false);
              setUnitId(undefined);
              setLabelName('Painel Geral');
              triggerButtonEvent('Geral');
            },
          },
          ...consumptionByUnit,
        ];
      };

      setConsumptionByUnit(generatePopupListIfGroupsHasMoreThanOneUnit());
    }
  };

  const getConsumptionPeriodsPopUpList = (totalMonthlyConsumption: any[]) => {
    const consumptionByMonth = totalMonthlyConsumption
      .map((monthlyConsumption: any) => ({
        label: getMonthYearLabel(monthlyConsumption.referenceMonthYear),
        onClick: () => {
          setOpenPeriodSelector(false);
          setLabelMonthYear(getMonthYearLabel(monthlyConsumption.referenceMonthYear));
          setMonthYear(monthlyConsumption.referenceMonthYear);
          triggerButtonEvent(getMonthYearLabel(monthlyConsumption.referenceMonthYear));
        },
      }))
      .reverse();

    setConsumptionPeriods(consumptionByMonth);
  };

  function createBarChartLabels<T extends MonthlyControlMeasurementByUnitId | MonthlyGroupControlMeasurement>(
    allMonthDatetimeMeasurements: T[],
  ) {
    const dates: string[] = [];
    if (allMonthDatetimeMeasurements) {
      return allMonthDatetimeMeasurements
        .sort((current, next) => moment(current.date).unix() - moment(next.date).unix())
        .map((item) => {
          if (item.totalConsumption > 0) dates.push(item.date);
          setDatesWithValues(dates);
          return item.date.substring(8, 10);
        });
    }
  }

  function createBarChartValuesInKwh<T extends MonthlyControlMeasurementByUnitId | MonthlyGroupControlMeasurement>(
    allMonthDatetimeMeasurements: T[],
  ) {
    return allMonthDatetimeMeasurements
      .sort((current, next) => moment(current.date).unix() - moment(next.date).unix())
      .map((item) => {
        return item.totalConsumption * 1000;
      });
  }

  const createBarChartProjectionLabels = (consumptionProjection: IDateProjection[]) => {
    return consumptionProjection.slice(controlDocsLength).map((item) => item?.date?.substring(8, 10));
  };

  const createBarChartProjectionValuesInKwh = (consumptionProjection: IDateProjection[]) => {
    return consumptionProjection.map((item, index) => {
      if (controlDocsLength !== 0 && datesWithValues.includes(item.date)) return 0;
      return Number(item.consumptionPredictedConstrained) * 1000;
    });
  };

  const createLineChartLabels = (datetimeMeasurementsByDay: DatetimeMeasurement[]) => {
    const validMeasurements = datetimeMeasurementsByDay?.filter((measure) => measure.subType === 'L');
    return validMeasurements?.map((item) => (item.subType === 'L' ? item.datetime.substring(11, 13) : null));
  };

  const createLineChartValues = (datetimeMeasurementsByDay: DatetimeMeasurement[]) => {
    const validMeasurements = datetimeMeasurementsByDay?.filter((measure) => measure.subType === 'L');
    return validMeasurements?.map((item) =>
      item.status === DatetimeMeasurementStatus.HCC && item.subType === 'L'
        ? Number(item.activeConsumption) * 1000
        : null,
    );
  };

  const createGroupLineChartLabels = (datetimeMeasurementsByDay: GroupDatetimeMeasurement[]) => {
    const datetime: string[] = datetimeMeasurementsByDay?.reduce((acc: string[], item) => {
      const hour = item.datetime.substring(11, 13);
      if (!acc.includes(hour)) {
        acc.push(hour);
      }
      return acc;
    }, []);

    return datetime?.map((item) => item);
  };

  const createGroupLineChartValues = (datetimeMeasurementsByDay: GroupDatetimeMeasurement[]) => {
    const valueForSameDate = datetimeMeasurementsByDay?.reduce(
      (acc: Array<{ datetime: string; activeConsumption: number }>, item) => {
        const existingItem = acc.find((entry) => entry.datetime === item.datetime);
        if (existingItem) {
          existingItem.activeConsumption += Number(item.activeConsumption);
        } else {
          acc.push({ datetime: item.datetime, activeConsumption: Number(item.activeConsumption) });
        }
        return acc;
      },
      [],
    );
    return valueForSameDate?.map((item) => item.activeConsumption * 1000);
  };

  useEffect(() => {
    if (monthYear !== getMonthYear(getActualMonth(user.group?.id))) {
      setBarChartSecondValues(undefined);
      setBarChartSecondLabels(undefined);
    }
  }, [monthYear]);

  useEffect(() => {
    if (units && monthlyControlMeasurementByUnitId && monthlyControlMeasurementByUnitId?.length > 0) {
      const intervalDate = dateMeasurement(monthlyControlMeasurementByUnitId);
      setMeasurementPeriod(intervalDate);
    }
  }, [units, monthlyControlMeasurementByUnitId]);

  useEffect(() => {
    if (unitId && consolidatedMeasurementById) {
      setLineChartLabels(createLineChartLabels(consolidatedMeasurementById?.datetimeMeasurement));
      setLineChartValues(createLineChartValues(consolidatedMeasurementById?.datetimeMeasurement));
    } else if (!unitId && groupConsolidatedMeasurementById) {
      setLineChartLabels(createGroupLineChartLabels(groupConsolidatedMeasurementById?.datetimeMeasurement));
      setLineChartValues(createGroupLineChartValues(groupConsolidatedMeasurementById?.datetimeMeasurement));
    }
  }, [consolidatedMeasurementById, unitId, groupConsolidatedMeasurementById]);

  useEffect(() => {
    let lastMeasurement;

    if (unitId && monthlyControlMeasurementByUnitId) {
      lastMeasurement = ConsumptionPageRenderHandler(monthlyControlMeasurementByUnitId);
      setConsolidatedMeasurementDocId(lastMeasurement?.consolidatedMeasurementDocId);
    } else if (!unitId && monthlyGroupControlMeasurement) {
      lastMeasurement = ConsumptionPageRenderHandler(monthlyGroupControlMeasurement);
      setGroupConsolidatedMeasurementDocId(lastMeasurement?.groupConsolidatedMeasurementDocId);
    }
    setLabelDate(formatStringDate(lastMeasurement?.date as string));
  }, [monthlyControlMeasurementByUnitId, unitId, monthlyGroupControlMeasurement]);

  useEffect(() => {
    if (monthYear === getMonthYear(getActualMonth(user.group?.id))) {
      let consumptionProjectionFilled: IDateProjection[] = [];
      if (unitId && consumptionProjectionByUnit) {
        consumptionProjectionFilled = autoFillConsumptionProjection(
          consumptionProjectionByUnit.dailyConsumptionProjection,
        );
      } else if (!unitId && consumptionProjectionByGroup) {
        consumptionProjectionFilled = autoFillConsumptionProjection(
          consumptionProjectionByGroup.dailyConsumptionProjection,
        );
      }
      setBarChartSecondValues(createBarChartProjectionValuesInKwh(consumptionProjectionFilled));
      setBarChartSecondLabels(createBarChartProjectionLabels(consumptionProjectionFilled));
    }
  }, [consumptionProjectionByUnit, consumptionProjectionByGroup, unitId, controlDocsLength, monthYear]);

  useMemo(() => {
    if (user?.group) {
      const actualMonthYear = getMonthYear(getActualMonth(user.group?.id));
      setMonthYear(actualMonthYear);
      setLabelMonthYear(getMonthYearLabel(actualMonthYear));
      setGroupId(user.group.id);
    }
  }, [user]);

  useMemo(() => {
    if (units) {
      const alphabeticalOrderUnits = units.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      setUnitId(undefined);
      setLabelName('Painel Geral');
      generateConsumptionByUnitPopUpList(alphabeticalOrderUnits);
    }
  }, [units]);

  useMemo(() => {
    const totalMonthlyConsumption = unitId ? totalMonthlyConsumptionByUnit : totalMonthlyConsumptionByGroup;

    if (totalMonthlyConsumption && totalMonthlyConsumption?.length > 0) {
      const totalMonths =
        user.group?.id === CLARKE_CUSTOMER_GROUP_ID
          ? ClarkeCustomerConsumptionPeriods
          : includeCurrentMonthOnFirstDay(totalMonthlyConsumption);

      getConsumptionPeriodsPopUpList(totalMonths);
    }
  }, [totalMonthlyConsumptionByUnit, totalMonthlyConsumptionByGroup, unitId]);

  if (isFetchingUser || loading) {
    return (
      <Layout.Content
        header={{
          title: '',
          displayDesktop: true,
        }}
      >
        <ConsumptionSkeleton />
      </Layout.Content>
    );
  }

  return (
    <div ref={exportRef}>
      <Layout.Content
        header={{
          title: 'Consumo',
          displayDesktop: true,
          breadCrumbLinks: [
            {
              label: 'Home',
              url: HOME_PATH,
            },
            {
              label: 'Consumo',
              url: CONSUMPTION_DASHBOARD_PATH,
            },
          ],
        }}
      >
        {consumptionPeriods && (
          <PopUpList
            items={consumptionPeriods}
            show={openPeriodSelector}
            className="absolute right-20 top-44 mr-8 -mt-8 cursor-pointer sm:right-24 sm:top-32"
          />
        )}
        {units && units?.length > 0 ? (
          <div className="grid grid-cols-3 col-span-full gap-8 pb-10">
            <React.Fragment key={''}>
              <PageSectionGenerator
                title={labelName}
                buttonIconProps={
                  consumptionByUnit && {
                    openPopupList: openConsumptionByUnitSelector,
                    setOpenPopupList: () => setOpenConsumptionByUnitSelector(!openConsumptionByUnitSelector),
                  }
                }
                popUpListProps={
                  consumptionByUnit && {
                    items: consumptionByUnit,
                    show: openConsumptionByUnitSelector,
                  }
                }
                subInfo={
                  monthlyControlMeasurementByUnitId && monthlyControlMeasurementByUnitId.length > 0
                    ? measurementPeriod
                    : ''
                }
              >
                <ConsumptionCompilationContent
                  barChartLabels={barChartLabels}
                  barChartValues={barChartValues}
                  barChartSecondValues={barChartSecondValues}
                  barChartSecondLabels={barChartSecondLabels}
                  controlDocsLength={controlDocsLength}
                  setLabelDate={setLabelDate}
                  lineChartLabels={lineChartLabels}
                  lineChartValues={lineChartValues}
                  labelDate={labelDate}
                  setLabelMonthYear={setLabelMonthYear}
                />
              </PageSectionGenerator>
            </React.Fragment>
          </div>
        ) : (
          <MessageComponent
            title={InformationMessagesByConsumptionMoment.CONSUMPTION_NOT_STARTED.title}
            description={InformationMessagesByConsumptionMoment.CONSUMPTION_NOT_STARTED.description}
            mainButtonLabel={InformationMessagesByConsumptionMoment.CONSUMPTION_NOT_STARTED.mainButtonLabel}
            mainButtonAction={() => navigate(MIGRATION_REPORT_PATH)}
          />
        )}
      </Layout.Content>
    </div>
  );
};

export default ConsumptionHub;
