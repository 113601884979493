import React from 'react';
import { Select, SelectProps } from '@clarke-energia/foton-v2';

export const SelectIcon: React.FC<SelectProps> = ({ ...props }) => {
  return (
    <Select.Root>
      <Select.Input {...props} />
    </Select.Root>
  );
};
