import { Button } from '@clarke-energia/foton-v2';

interface ReturnButtonProps {
  onClick: () => void;
}

const ReturnButton = (props: ReturnButtonProps) => {
  return (
    <Button kind="secondary" variant="ghost" size="medium" onClick={props.onClick} leftIcon="ArrowLeftIcon">
      Voltar
    </Button>
  );
};

export default ReturnButton;
