import { CardNumberProps } from '@clarke-energia/foton-v2';
import { EconomyReportIconCardData, EconomyReportIconCardDataItem } from '@pages/economy-reports/types';

export const getRatio = (data: EconomyReportIconCardDataItem) => {
  return {
    value: data.percentage ?? '',
    direction: data.direction ?? 'none',
  };
};

export const buildCardNumberProps = (data: EconomyReportIconCardData): CardNumberProps[] => {
  return [
    {
      icon: 'CurrencyDollarIcon',
      title: 'Economia',
      value: data.economy.total,
      ratio: getRatio(data.economy),
      variant: 'compact',
    },
    {
      icon: 'BanknotesIcon',
      title: 'Custo',
      value: data.energy.cost.total,
      ratio:
        data.energy.cost.direction && data.energy.cost.direction !== 'none' ? getRatio(data.energy.cost) : undefined,
      variant: 'compact',
    },
    {
      icon: 'LightBulbIcon',
      title: 'Consumo',
      value: data.energy.consumption.total,
      ratio:
        data.energy.consumption.direction && data.energy.consumption.direction !== 'none'
          ? getRatio(data.energy.consumption)
          : undefined,
      variant: 'compact',
    },
  ];
};
