import React from 'react';
import { twMerge } from 'tailwind-merge';
import { HeroIcon } from '@clarke-energia/foton-v2';

import style from './style.module.css';

interface EmptyDataProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  description: string;
}

const EmptyData: React.FC<EmptyDataProps> = ({ title, description, ...attrs }) => {
  const { className, ...restAttrs } = attrs;
  return (
    <div className={twMerge(style.container, className)} {...restAttrs}>
      <HeroIcon icon="XCircleIcon" solid extraClassNames={style.icon} />
      <p className={style.title}>{title ? title : 'Você ainda não está no Mercado Livre de Energia.'}</p>
      <p className={style.description}>{description}</p>
    </div>
  );
};

export default EmptyData;
