import { useLazyQuery } from '@apollo/client';

import {
  QUERY_GET_CONSUMPTION_FROM_ECONOMY_REPORT_PER_GROUP,
  QUERY_GET_CONSUMPTIONS_FROM_UNIT_ECONOMY_REPORT_BY_GROUP_ID,
} from './queries';
import { ConsumptionReportGroupGraphQL, UnitConsumptionReportGraphQL } from './types';

export const useConsumptionReportsByGroup = () => {
  const [getConsumptionReportsByGroup, { data, loading, error }] = useLazyQuery<ConsumptionReportGroupGraphQL>(
    QUERY_GET_CONSUMPTION_FROM_ECONOMY_REPORT_PER_GROUP,
  );

  const [getConsumptionsFromSingleUnitReportsByGroup, { data: unitData, loading: unitLoading, error: unitError }] =
    useLazyQuery<UnitConsumptionReportGraphQL>(QUERY_GET_CONSUMPTIONS_FROM_UNIT_ECONOMY_REPORT_BY_GROUP_ID);

  const getConsumptionsReportsHandler = (groupId: string, singleUnit: boolean) => {
    if (singleUnit) {
      return getConsumptionsFromSingleUnitReportsByGroup({
        variables: { groupId },
      }).then((response) =>
        response.data?.unitsEconomyReportsByGroupId.map(({ unit: { group }, date, energyTotalConsumption }) => ({
          date,
          totalMonthlyEnergyConsumption: energyTotalConsumption,
          group,
        })),
      );
    } else {
      return getConsumptionReportsByGroup({
        variables: { groupId },
      }).then((response) => response.data?.getAllGroupsEconomyReportByGroupID);
    }
  };

  return {
    getConsumptionsReportsHandler,
    consumptionsData: data || unitData,
    loadingConsumptions: loading || unitLoading,
    error: error || unitError,
  };
};
