import {
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
  UnitEconomyReportDataResponse,
} from '@hooks/economy/types';

export enum EnergyMarketType {
  ACL = 'ACL',
  ACR = 'ACR',
}

export type IEnergyMarketType = EnergyMarketType.ACL | EnergyMarketType.ACR;

export interface IPreviewGadgetContentGenerator {
  totalValue?: number;
  marketType: IEnergyMarketType;
}

export interface IReportGadgetGenerator {
  unitEconomyReport: UnitEconomyReportDataResponse;
  tables: Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>;
  marketType: IEnergyMarketType;
}
