import { GroupAccumulatedEconomyReportByGroupId } from './types';
import { GroupAccumulatedEconomyReportByGroupIdGraphQLData } from './schemas';

export class GroupAccumulatedEconomyReportManager {
  rawData: GroupAccumulatedEconomyReportByGroupIdGraphQLData;
  groupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupId;

  constructor(rawData: GroupAccumulatedEconomyReportByGroupIdGraphQLData) {
    this.rawData = rawData;
    this.groupAccumulatedEconomyReport = rawData.getGroupAccumulatedEconomyReportByGroupID;
  }
}
