import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-label';

import { useUserInfo } from '@hooks/use-user-info';

import Layout from '@components/templates/screen-layout';
import { HomeSkeleton } from '@components/molecules/skeleton/home-skeleton';
import CardWithDescription from '@components/molecules/card-with-description';
import { cardsInformation } from './static-data';
import { formatButtonUrl } from '@utils/text';

const HomePage: React.FC = () => {
  const { user, isFetchingUser } = useUserInfo();
  const [pageTitle, setPageTitle] = React.useState('');

  const handleCardClick = (destinationPath: string, buttonText: string) => {
    const eventProperties = {
      page_name: 'Home',
      button_text: buttonText,
      button_url: formatButtonUrl(destinationPath),
      button_clicked_at: dayjs().toString(),
    };
    eventTracker.trackEvent(eventLabels.BUTTON_CLICKED, eventProperties);
  };

  useEffect(() => {
    if (user) {
      setPageTitle(user.group?.name.length ? user.group?.name : user.name);
    }
  }, [user]);

  if (isFetchingUser)
    return (
      <Layout.Content
        header={{
          title: '',
          displayDesktop: true,
        }}
      >
        <HomeSkeleton />
      </Layout.Content>
    );

  return (
    <Layout.Content
      header={{
        title: pageTitle,
        displayDesktop: true,
      }}
    >
      <div className="col-span-11 mb-6 h-full sm:mx-5">
        <div className="mb-2 md:first-section first-section">
          <h1 className="w-full text-4xl font-medium text-left">Bem vindo de volta!</h1>
          <p className="mt-4 w-5/6 md:w-4/6">
            Essa é a área do cliente. Tudo em um só lugar para você acompanhar suas cotações de energia, etapas da
            migração e os relatórios mensais de economia. Além de ter em mãos documentos e arquivos importantes.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:w-full md:grid-cols-2 md:w-full w-[330px]">
          {cardsInformation.map((cardInfo) => {
            return (
              <CardWithDescription
                key={cardInfo.id}
                icon={cardInfo.icon}
                label={cardInfo.title}
                description={cardInfo.description}
                path={cardInfo.path}
                onCardClick={() => handleCardClick(cardInfo.path, cardInfo.title)}
              />
            );
          })}
        </div>
      </div>
    </Layout.Content>
  );
};

export default HomePage;
