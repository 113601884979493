import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Card, TooltipComponent } from '@clarke-energia/foton-v2';

import style from './style.module.css';

export interface ChartContainerProps {
  children: React.ReactNode;
  title: {
    text: string;
    className?: string;
  };
  className: string;
  tooltipContent?: string;
}

const ChartContainer: React.FC<ChartContainerProps> = ({ title, tooltipContent, children, className = '' }) => {
  return (
    <Card.Root className={className}>
      <Card.Header>
        <p>
          <span className={twMerge(style.title, title.className)}>{title.text}</span>

          {tooltipContent && (
            <span>
              <span className={style.tooltip}>
                <TooltipComponent icon={{ icon: 'InformationCircleIcon' }} content={tooltipContent} side="top" />
              </span>
            </span>
          )}
        </p>
      </Card.Header>
      {children}
    </Card.Root>
  );
};

export default ChartContainer;
