import { Table, TooltipComponent } from '@clarke-energia/foton-v2';

import { formatCurrency } from '@utils/text';

import { EnergyMarketType, IPreviewGadgetContentGenerator } from './types';
import { getMarketName, getTooltipMessage } from './functions';
import {
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
  UnitEconomyReportDataResponse,
} from '@hooks/economy/types';

import style from './style.module.css';
import { twMerge } from 'tailwind-merge';

export interface EconomyComparisonTableProps {
  marketType: EnergyMarketType;
  unitEconomyReport: UnitEconomyReportDataResponse;
  tables: Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>;
}

export const EconomyComparisonTable = ({
  unitEconomyReport,
  tables,
  marketType,
}: EconomyComparisonTableProps): JSX.Element => {
  const usedReport =
    marketType === EnergyMarketType.ACL ? unitEconomyReport.freeMarket : unitEconomyReport.conventionalMarket;

  return (
    <div className={style.mainContainer}>
      <div className={style.titleContainer}>
        <h4 className={style.title}>
          {marketType === EnergyMarketType.ACL ? 'Custos Mercado Livre' : 'Simulação Mercado Cativo'}
        </h4>
        <TooltipComponent
          content={getTooltipMessage(marketType)}
          side="top"
          icon={{
            icon: 'InformationCircleIcon',
          }}
        />
      </div>
      <div className={style.innerContainer}>
        <PreviewGadgetContentGenerator marketType={marketType} totalValue={usedReport.totalValue} />
        {tables &&
          Object.values(tables).map((table, index) => (
            <div className={style.tableContainer} key={index}>
              <div>
                <h3 className={style.tableHeader}>{table.superheader}</h3>
              </div>
              <div className={style.table}>
                <Table data={table.data} tableColumns={table.columnDefinitions} />
              </div>
            </div>
          ))}
        <div className={twMerge(style.footer, marketType == EnergyMarketType.ACL ? 'bg-primary-30' : 'bg-orange-20')}>
          <div>Total Mercado {getMarketName(marketType)} (s/impostos)</div>
          <div>{formatCurrency(usedReport.totalValueWithoutTax)}</div>
        </div>
      </div>
    </div>
  );
};

const PreviewGadgetContentGenerator = ({ totalValue, marketType }: IPreviewGadgetContentGenerator): JSX.Element => (
  <div className={style.headerContainer}>
    <div className={style.headerTitle}>Total Mercado {getMarketName(marketType)} (c/impostos)</div>
    <div className={style.headerValue}>{formatCurrency(totalValue ?? 0)}</div>
  </div>
);
