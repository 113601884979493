import numeral from 'numeral';

import { formatCurrency, formatNumber } from '@utils/text';

import {
  GroupAccumulatedEconomyReportByGroupIdParsed,
  SummaryEconomyReportsHistoryParsed,
  UnitEconomyReportDataResponse,
  UnitReportParsed,
} from '@hooks/economy/types';

import { getDirectionFromValue } from '../functions';
import { EconomyReportIconCardData, UnitSummaryEconomyReport } from '../types';

export const buildEconomyCardDataFromSummary = (
  summary: SummaryEconomyReportsHistoryParsed,
): EconomyReportIconCardData => {
  return {
    economy: {
      total: summary.monthlySavings.total,
      percentage: summary.monthlySavings.percentage,
      direction: summary.monthlySavings.typePercentageStyle,
    },
    energy: {
      cost: {
        total: summary.monthlyEnergyCost.total,
        direction: 'none',
        percentage: summary.monthlyEnergyCost.percentage,
      },
      consumption: {
        total: summary.monthlyEnergyConsumption.total,
        direction: 'none',
        percentage: summary.monthlyEnergyConsumption.percentage,
      },
    },
  };
};

export const buildEconomyCardDataFromUnitReport = (unitReport: UnitReportParsed): EconomyReportIconCardData => {
  return {
    economy: {
      total: unitReport.economy.total,
      percentage: unitReport.economy.percentage,
      direction: unitReport.economy.typePercentageStyle,
    },
    energy: {
      cost: {
        total: unitReport.energyCost.total,
        percentage: unitReport.energyCost.percentageBalance,
        direction: unitReport.energyCost.typePercentageStyle,
      },
      consumption: {
        total: unitReport.energyConsumption.total,
        percentage: unitReport.energyConsumption.percentageBalance,
        direction: unitReport.energyConsumption.typePercentageStyle,
      },
    },
  };
};

export const buildEconomyCardDataFromUnitEconomyReport = (
  unitReport: UnitEconomyReportDataResponse,
): EconomyReportIconCardData => {
  return {
    economy: {
      total: formatCurrency(unitReport.currentSavings),
      percentage: numeral(unitReport.savingBalancePercent / 100).format('% 0'),
      direction: getDirectionFromValue(unitReport.savingBalancePercent),
    },
    energy: {
      cost: {
        total: formatCurrency(unitReport.energyTotalCost),
        direction: getDirectionFromValue(unitReport.energyCostBalancePercent),
        percentage: numeral(unitReport.energyCostBalancePercent / 100).format('% 0'),
      },
      consumption: {
        total: `${formatNumber(unitReport.energyTotalConsumption)} MWh`,
        direction: getDirectionFromValue(unitReport.energyConsumptionBalancePercent),
        percentage: numeral(unitReport.energyConsumptionBalancePercent / 100).format('% 0'),
      },
    },
  };
};

export const buildEconomyCardDataFromGroupAccumulatedReport = (
  groupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupIdParsed,
): EconomyReportIconCardData => {
  return {
    economy: {
      total: groupAccumulatedEconomyReport.totalSavings,
      percentage: groupAccumulatedEconomyReport.totalSavingsPercentage,
      direction: groupAccumulatedEconomyReport.totalSavingsPercentageTagType,
    },
    energy: {
      cost: {
        total: groupAccumulatedEconomyReport.totalEnergyCost,
        direction: 'none',
        percentage: undefined,
      },
      consumption: {
        total: groupAccumulatedEconomyReport.totalEnergyConsumption,
        direction: 'none',
        percentage: undefined,
      },
    },
  };
};

export const getUnitEconomyCardsData = (parsedUnits: UnitReportParsed[]): UnitSummaryEconomyReport[] => {
  return parsedUnits
    ? parsedUnits.map((units) => {
        return {
          unit: {
            id: units.unitId,
            name: units.unitName,
          },
          economy: units.economy.total,
          energy: {
            cost: units.energyCost.total,
            consumption: units.energyConsumption.total,
          },
        };
      })
    : [];
};
