import Skeleton from 'react-loading-skeleton';

import style from './style.module.css';

const NuggetSkeleton: React.FC = () => (
  <Skeleton width="100%" height="100%" wrapper={({ children }) => <div className={style.nugget}>{children}</div>} />
);

export const NuggetInfosAreaSkeleton: React.FC = () => (
  <div className={style.container}>
    <NuggetSkeleton />
    <NuggetSkeleton />
    <NuggetSkeleton />
    <NuggetSkeleton />
    <NuggetSkeleton />
    <NuggetSkeleton />
    <NuggetSkeleton />
    <NuggetSkeleton />
  </div>
);
