import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card } from '@clarke-energia/foton-v2';

const getThreeCards = (name: string) => {
  return Array(3)
    .fill({ w: '100%', h: 100 })
    .map((skeleton, index) => {
      return (
        <Card.Root className="w-full" key={`generic-cards-skeleton-${name}-${index}`}>
          <Card.Content className="flex flex-row gap-5">
            <div className="w-full md:w-1/3">
              <Skeleton className="rounded-medium" height={skeleton.h} width={skeleton.w} />
            </div>
            <div className="w-full md:w-2/3">
              <Skeleton className="rounded-medium" height={skeleton.h} width={skeleton.w} />
            </div>
          </Card.Content>
        </Card.Root>
      );
    });
};

export const EconomyReportSkeleton: React.FC = () => (
  <div className="grid gap-5">
    <div className="grid grid-cols-3 col-span-full gap-8">{getThreeCards('economy-cards')}</div>
    <div className="grid grid-cols-1 col-span-full gap-8 lg:grid-cols-3">
      <div className="col-span-full md:col-span-1">
        <Card.Root>
          <Card.Content>
            <Skeleton className="rounded-medium" key={`economy-chart-skeleton`} height={150} width={'100%'} />
          </Card.Content>
        </Card.Root>
      </div>
      <div className="col-span-full md:col-span-2">
        <Card.Root>
          <Card.Content>
            <Skeleton className="rounded-medium" key={`cost-chart-skeleton`} height={150} width={'100%'} />
          </Card.Content>
        </Card.Root>
      </div>
    </div>
    <div className="grid grid-cols-3 col-span-full gap-8">{getThreeCards('unit-economy-cards')}</div>
  </div>
);
