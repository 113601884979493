import { IconName, NuggetInfo, Tag } from '@clarke-energia/foton-v2';
import { twMerge } from 'tailwind-merge';

import { useIsMobile } from '@hooks/is-mobile';

import style from './style.module.css';

export interface NuggetTagHeaderProps {
  icon: {
    icon: IconName;
    extraClassNames?: string;
  };
  title: string;
  tag: {
    icon: IconName;
    label: string;
    className?: string;
  };
}

const NuggetTagHeader = (props: NuggetTagHeaderProps) => {
  const isMobile = useIsMobile();

  return (
    <div className={style.container}>
      <NuggetInfo
        info={props.title}
        icon={{
          icon: props.icon.icon,
          extraClassNames: twMerge(props.icon.extraClassNames, style.icon),
        }}
        sizes={{
          info: isMobile ? 14 : 16,
          title: isMobile ? 26 : 32,
          icon: isMobile ? 24 : 32,
        }}
      />
      <Tag
        label={props.tag.label}
        kind="icon"
        className={twMerge(style.tag, props.tag.className)}
        icon={{
          icon: props.tag.icon,
        }}
      />
    </div>
  );
};

export default NuggetTagHeader;
