import { UnitSummaryEconomyReport } from '@pages/economy-reports/types';

export const splitUnitsAsChunks = (units: UnitSummaryEconomyReport[]) => {
  const unitChunks = [];
  const maxSize = 3;
  const lengthUnits = units.length;
  const numChunks = Math.ceil(lengthUnits / 3);

  for (let i = 0; i < numChunks; i++) {
    const chunk = units.slice(i * maxSize, (i + 1) * maxSize);
    unitChunks.push(chunk);
  }
  return unitChunks;
};

export const getVariant = (isMobile: boolean, screenWidth: number) => {
  const breakPointsCompact = (screenWidth > 1280 && screenWidth < 1600) || screenWidth > 2000;
  return isMobile || breakPointsCompact ? 'compact' : 'normal';
};
