import { IconName } from '@clarke-energia/foton-v2';
import { TaskType } from '@hooks/migration-report/types';

export const taskTypeMapIcon: Record<TaskType, IconName> = {
  SOCIAL_CONTRACT: 'DocumentIcon',
  LEGAL_REP_ID: 'IdentificationIcon',
  FINANCIAL_BALANCE: 'CurrencyDollarIcon',
  DISTRIBUTOR_LOGIN: 'KeyIcon',
  PRODUCT_MODELING: 'HandRaisedIcon',
  REQUEST_QUOTE: 'DocumentIcon',
  ENERGY_SUPPLIER_TERM: 'LightBulbIcon',
  CCEAL_SIGNATURE: 'PencilIcon',
  VIGENCY_DATE: 'CalendarIcon',
  CONTRACT_TERMINATION: 'DocumentIcon',
  DENUNCIATION_LETTER: 'EnvelopeIcon',
  SEND_DENUNCIATION: 'DocumentTextIcon',
  CONFIRM_DENUNCIATION_RECEIPT: 'CheckIcon',
  MEASUREMENT_INSPECTION: 'AdjustmentsHorizontalIcon',
  SMF_ADJUSTMENT_QUOTE: 'WrenchIcon',
  ENSURE_SMF_ADJUSTMENT: 'CheckIcon',
  SUPPLIER_BUDGET: 'BriefcaseIcon',
  ENSURE_SUPPLIER_CONTRACT: 'ClipboardIcon',
  COMMISSIONING_REPORT_APPROVAL: 'CheckBadgeIcon',
  MEASUREMENT_POINT_REGISTRATION: 'AdjustmentsVerticalIcon',
  SEND_DHC: 'TruckIcon',
  MAPPED_MEASUREMENT_POINT: 'MapIcon',
  CCV_SIGNATURE: 'PencilIcon',
  CLIENT_PACT_SIGNATURE: 'PencilIcon',
  CLIENT_CUSD_SIGNATURE: 'PencilIcon',

  NO_MAPPED: 'BoltIcon',
};
