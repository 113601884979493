import React from 'react';
import { twMerge } from 'tailwind-merge';
import { EconomyChartProps } from './types';

import style from './style.module.css';

const EconomyChart: React.FC<EconomyChartProps> = ({
  freeMarket,
  conventionalMarket,
  economy,
  economyPrefix = '%',
  height = '250px',
  ...props
}) => {
  return (
    <div {...props}>
      <div className={style.mainContainer}>
        <div className={style.economyValue}>{economy + economyPrefix}</div>
        <div
          className={style.innerContainer}
          style={{
            height: height,
          }}
        >
          <div className={twMerge(style.bar, style.mainBar, conventionalMarket.color)}>
            <h3 className={style.marketValue}>{conventionalMarket.value}</h3>
            <p className={style.marketTitle}>{conventionalMarket.title}</p>
          </div>
          <div
            className={twMerge(style.bar, style.secondaryBar, freeMarket.color)}
            style={{
              transition: 'all 0.25s linear',
              height: `calc(100% - ${economy}%)`,
            }}
          >
            <h3 className={style.marketValue}>{freeMarket.value}</h3>
            <p className={style.marketTitle}>{freeMarket.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EconomyChart;
