import React, { ForwardedRef, forwardRef } from 'react';
import { Accordion, Card, ColoredIcon, TooltipComponent } from '@clarke-energia/foton-v2';

import { EnergyMarketType } from '../economy-comparison-table/types';
import { EconomyComparisonTable } from '../economy-comparison-table';
import {
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
  UnitEconomyReportDataResponse,
} from '@hooks/economy/types';

import ExportSelectIcon from '@components/molecules/export-select/icon';

import style from './style.module.css';

interface EconomyComparisonHeaderProps {
  exportComponent?: {
    onChange: (value: string) => void;
  };
}

interface EconomyComparisonContentProps {
  report: UnitEconomyReportDataResponse | null;
  tables: {
    freeMarket?: Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>;
    conventionalMarket?: Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>;
  };
}

export interface EconomyComparisonProps extends EconomyComparisonHeaderProps, EconomyComparisonContentProps {
  content: {
    ref: ForwardedRef<HTMLDivElement>;
  };
}

const Header: React.FC<EconomyComparisonHeaderProps> = ({ exportComponent }) => {
  return (
    <Card.Header className={style.headerContainer}>
      <div className={style.headerMainContainer}>
        <div>
          <ColoredIcon
            icon={{
              icon: 'BanknotesIcon',
            }}
            colors={{
              backgroundColor: 'bg-primary-10',
              color: 'text-primary-0',
            }}
            className={style.icon}
          />
        </div>
        <Card.Title title="Comparativo de Economia" props={{ className: style.header }} />
        <TooltipComponent
          extraClassName={style.tooltip}
          content="Comparação entre os custos de energia entre o Mercado Livre e o Mercado Cativo. Confira os custos totais para entender melhor sua economia."
          side="top"
          icon={{
            icon: 'InformationCircleIcon',
          }}
        />
      </div>
      <div className={style.exportSelectContainer}>
        <ExportSelectIcon onChange={exportComponent?.onChange} />
      </div>
    </Card.Header>
  );
};

const Content = forwardRef<HTMLDivElement, EconomyComparisonContentProps>(function Content({ report, tables }, ref) {
  return (
    <div ref={ref}>
      <Card.Content className={style.cardContainer}>
        <div className={style.tables}>
          {report && tables.freeMarket && (
            <EconomyComparisonTable
              marketType={EnergyMarketType.ACL}
              unitEconomyReport={report}
              tables={tables.freeMarket}
            />
          )}
          {report && tables.conventionalMarket && (
            <EconomyComparisonTable
              marketType={EnergyMarketType.ACR}
              unitEconomyReport={report}
              tables={tables.conventionalMarket}
            />
          )}
        </div>
      </Card.Content>
    </div>
  );
});

const EconomyComparison: React.FC<EconomyComparisonProps> = ({ report, tables, exportComponent, content }) => {
  return (
    <Card.Root>
      <Accordion
        header={<Header exportComponent={exportComponent} />}
        content={<Content ref={content.ref} report={report} tables={tables} />}
      />
    </Card.Root>
  );
};

export default EconomyComparison;
