import React from 'react';

import { SelectIcon } from '@components/atoms/form/select-icon';

import { populateOptions } from './functions';
import { ExportSelectProps, OptionsMap } from './types';

const exportsMap: OptionsMap = {
  png: {
    label: 'PNG',
    value: 'PNG',
  },
  excel: {
    label: 'Excel',
    value: 'EXCEL',
  },
};

const ExportSelect: React.FC<ExportSelectProps> = ({
  onChange,
  availableExports = {
    png: true,
    excel: true,
  },
}) => {
  const options = populateOptions(availableExports, exportsMap);

  return (
    <SelectIcon
      options={options}
      onChange={(val) => {
        onChange?.(val);
      }}
      value={''}
      placeholder="Exportar"
      prependIcon="ArrowDownTrayIcon"
      bordered
    />
  );
};

export default ExportSelect;
