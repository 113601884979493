import { useLocation } from 'react-router-dom';

export const useMenuItemActive = (pathName: string) => {
  const location = useLocation();

  const firstSegment = location.pathname.split('/')[1].split('-')[0];
  const targetSegment = pathName.split('/')[1].split('-')[0];

  return firstSegment === targetSegment;
};
