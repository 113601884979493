import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_MIGRATION_REPORT_BY_GROUP_ID } from './queries';
import { IMigrationReport } from './types';

export const useGetMigrationReportByGroup = () => {
  const [migrationReportByGroup, setMigrationReportByGroup] = React.useState<IMigrationReport>({} as IMigrationReport);
  const [getMigrationReportByGroup, { data, loading, error }] = useLazyQuery(GET_MIGRATION_REPORT_BY_GROUP_ID, {
    fetchPolicy: 'network-only',
  });

  function getMigrationReportByGroupHandler(groupId: string) {
    return getMigrationReportByGroup({ variables: { groupId } });
  }

  useEffect(() => {
    if (data) {
      setMigrationReportByGroup(data.getMigrationReportByGroupId);
    }
  }, [data]);

  return {
    getMigrationReportByGroupHandler,
    migrationReportByGroup,
    data,
    loading,
    error,
  };
};
