import React from 'react';
import { NuggetInfo } from '@clarke-energia/foton-v2';

import { useIsMobile } from '@hooks/is-mobile';

import style from './style.module.css';

export interface ContractPricesProps {
  visible: boolean;
  prices: { year: number; amount: string }[];
}

const EnergyContractPrices: React.FC<ContractPricesProps> = ({ prices, visible }) => {
  const isMobile = useIsMobile();
  const valuesTitle = prices.every(({ amount }) => amount.includes('%'))
    ? 'Desconto anual %'
    : `Preço Base ${'(R$/MWh)'}`;

  return (
    <div className={visible ? style.mainContainer : 'hidden'}>
      <div className={style.nuggetTextContainer}>
        <NuggetInfo
          title=""
          icon={{
            icon: 'CurrencyDollarIcon',
            extraClassNames: 'bg-gray-10 rounded-[7px]',
          }}
          info={isMobile ? valuesTitle : ''}
          sizes={{
            title: isMobile ? 8 : 12,
            info: isMobile ? 12 : 14,
            icon: 16,
            contentSpace: 4,
          }}
        />
      </div>

      <div className={style.readjustmentContainer}>
        <div className={style.yearContainer}>
          <span className={style.priceLabelWidth} />
          {prices.map(({ year }) => (
            <p className={style.year} key={year}>
              {year}
            </p>
          ))}
        </div>

        <hr />

        <div className={style.amountContainer}>
          <span className={style.priceLabelWidth}>
            <span className={style.priceLabel}>{valuesTitle}</span>
          </span>
          {prices.map(({ amount }, i) => (
            <p className={style.amount} key={`${amount}-${i}`}>
              {amount}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnergyContractPrices;
