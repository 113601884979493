import html2canvas from 'html2canvas';

export const exportElementAsImage = async (element: HTMLElement, exportName: string) => {
  const canvas = await html2canvas(element, {
    scale: 3,
    backgroundColor: '#f2f2f2',
    x: -20,
    y: -20,
    width: element.clientWidth + 40,
    height: element.clientHeight + 40,
  });
  const link = canvas.toDataURL('image/png');
  const linkElement = document.createElement('a');
  linkElement.href = link;
  linkElement.download = exportName + '.png';
  document.body.appendChild(linkElement);
  linkElement.click();

  document.body.removeChild(linkElement);
};

export const useExportElementAsImage = () => {
  return exportElementAsImage;
};
