import { useLazyQuery } from '@apollo/client';

import { UnitReportParsed, SummaryEconomyReportsHistory, GroupAccumulatedEconomyReportByGroupIdParsed } from './types';

import { parseGroupAccumulatedEconomyReport, formatUnitsAccumulatedSummaryEconomyReportsFromGroup } from './parser';

import {
  GroupAccumulatedEconomyReportByGroupIdGraphQLData,
  GroupAccumulatedEconomyReportByGroupIdQueryVariables,
} from './schemas';

import { GroupAccumulatedEconomyReportManager } from './manager';

import { QUERY_GET_GROUP_ACCUMULATED_ECONOMY_REPORT_BY_GROUP_ID } from './queries';

export const useGetGroupAccumulatedEconomyReportByGroupId = () => {
  const parsedData: {
    parsedGroupAccumulatedEconomyReport: GroupAccumulatedEconomyReportByGroupIdParsed;
    parsedUnitAccumulatedSummaryReport: UnitReportParsed[];
    rawSummaryEconomyReportsHistory?: SummaryEconomyReportsHistory[];
  } = {
    parsedGroupAccumulatedEconomyReport: {} as GroupAccumulatedEconomyReportByGroupIdParsed,
    parsedUnitAccumulatedSummaryReport: [],
    rawSummaryEconomyReportsHistory: [],
  };

  const [getGroupAccumulatedEconomyReportByGroupId, { data, loading, error }] = useLazyQuery<
    GroupAccumulatedEconomyReportByGroupIdGraphQLData,
    GroupAccumulatedEconomyReportByGroupIdQueryVariables
  >(QUERY_GET_GROUP_ACCUMULATED_ECONOMY_REPORT_BY_GROUP_ID);

  if (data) {
    const manager = new GroupAccumulatedEconomyReportManager(data);

    parsedData.parsedGroupAccumulatedEconomyReport = parseGroupAccumulatedEconomyReport(
      manager.groupAccumulatedEconomyReport,
    );
    parsedData.parsedUnitAccumulatedSummaryReport = formatUnitsAccumulatedSummaryEconomyReportsFromGroup(
      manager.groupAccumulatedEconomyReport.unitsAccumulatedSummaryEconomyReports ?? [],
    );
    parsedData.rawSummaryEconomyReportsHistory = manager.groupAccumulatedEconomyReport.summaryEconomyReportsHistory;
  }

  return {
    getGroupAccumulatedEconomyReportByGroupId,
    data: data,
    parsedData,
    loading,
    error,
  };
};
