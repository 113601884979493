import React from 'react';
import { Link } from 'react-router-dom';
import { MenuMobile, MenuProps } from '@clarke-energia/foton-v2';

import { useAuth0 } from '@auth0/auth0-react';
import { useUserInfo } from '@hooks/use-user-info';
import { useMenuItemActive } from '@hooks/menu-item-active';

import { BASE_MENU_ITENS } from '../sidebar/helper';

import style from './style.module.css';

const SidebarMobile: React.FC = () => {
  const { user } = useUserInfo();
  const { logout } = useAuth0();

  const menuItems = BASE_MENU_ITENS.map((section) => ({
    ...section,
    links: section.links.map((item) => ({
      ...item,
      isActive: useMenuItemActive(item.path),
      LinkComponent: Link,
    })),
  })) as MenuProps['menuItem'];

  return (
    <div className={style.container}>
      <MenuMobile
        handleLogout={logout}
        userInfo={{
          name: user.name,
          picture: { src: user?.photoUrl },
        }}
        menuItem={menuItems}
      />
    </div>
  );
};

export default SidebarMobile;
