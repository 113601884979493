import React from 'react';
import { twMerge } from 'tailwind-merge';

interface IPageSection extends React.HTMLAttributes<HTMLDivElement> {
  children: JSX.Element;
}

const PageSection = ({ children, ...attrs }: IPageSection): JSX.Element => {
  const { className } = attrs;

  return (
    <div className={twMerge('flex flex-col col-span-full gap-5 mx-5 h-full flex-grow lg:mx-0', className ?? '')}>
      {children}
    </div>
  );
};

export default PageSection;
