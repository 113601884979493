import React from 'react';
import { Button, ButtonProps, IconButton } from '@clarke-energia/foton-v2';
import { useIsMobile } from '@hooks/is-mobile';

export type MobileLikeIconButtonProps = ButtonProps;
const MobileLikeIconButton: React.FC<ButtonProps> = (props) => {
  const isMobile = useIsMobile();

  if (!(props.leftIcon || props.rightIcon)) {
    throw new Error('You must provide a leftIcon or rightIcon');
  }

  if (isMobile) {
    return (
      <IconButton
        {...props}
        // @ts-expect-error - verified above
        icon={props.leftIcon ? props.leftIcon : props.rightIcon}
        leftIcon={undefined}
        rightIcon={undefined}
      />
    );
  }

  return <Button {...props} />;
};

export default MobileLikeIconButton;
