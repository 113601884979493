import { NuggetInfo } from '@clarke-energia/foton-v2';
import leafIcon from '@assets/icons/leaf-icon.svg';
import treeIcon from '@assets/icons/tree-icon.svg';
import lightIcon from '@assets/icons/light-icon.svg';
import style from './style.module.css';

interface SustainabilityNuggetsProps {
  totalCo2Avoided: string;
  totalTreesSaved: string;
}

const SustainabilityNuggets: React.FC<SustainabilityNuggetsProps> = (props: SustainabilityNuggetsProps) => {
  return (
    <div className={style.container}>
      {[
        {
          title: 'Total de CO² evitado (t)',
          info: props.totalCo2Avoided,
          icon: <img src={leafIcon} className="w-[32px]" />,
        },
        {
          title: 'Equivalente em árvores (un)',
          info: props.totalTreesSaved,
          icon: <img src={treeIcon} className="w-[32px]" />,
        },
        {
          title: 'Tipo de energia',
          info: 'Incentivada 100% ',
          icon: <img src={lightIcon} className="w-[32px]" />,
        },
      ].map((data) => (
        <div className={style.nuggetContainer} key={data.title}>
          <NuggetInfo
            title={data.title}
            info={data.info}
            icon={<span className={style.icon}>{data.icon}</span>}
            strongInfo
            sizes={{
              title: 14,
              info: 24,
              contentSpace: 10,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default SustainabilityNuggets;
