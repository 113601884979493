import ReturnButton from '@components/atoms/buttons/return-button';
import { useNavigate } from 'react-router-dom';

import style from './style.module.css';

interface ReturnHeaderProps {
  returnPath: string;
}
const ReturnHeader = (props: ReturnHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <ReturnButton onClick={() => navigate(props.returnPath)} />
    </div>
  );
};

export default ReturnHeader;
