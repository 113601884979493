import React, { useRef, useEffect, useState, useMemo } from 'react';

const getChildWidth = (parentWidth: number, childMinWidth: number, gap: number, numChields: number) => {
  if (numChields === 0) {
    return childMinWidth;
  }
  if (numChields * childMinWidth + (numChields - 1) * gap <= parentWidth) {
    // -2 just to be sure
    return (parentWidth - (numChields - 1) * gap - 2) / numChields;
  }

  // - 2 just to be sure
  parentWidth = Math.floor(parentWidth - 2);
  let columnsNum = parentWidth / (childMinWidth + gap);
  columnsNum = Math.floor(columnsNum);
  const numGaps = columnsNum - 1;
  parentWidth = parentWidth - numGaps * gap;
  const childWidth = parentWidth / columnsNum;
  return Math.floor(childWidth);
};

interface CardContainerProps {
  children: React.ReactNode[];
  childMinWidth?: number;
  gap?: number;
}

/**
 * @description Renders multiple containers, always with the same width, automatically calculating the number of elements rendered in the same row until the end of the screen.
 */
const MultipleCardsContainer: React.FC<CardContainerProps> = ({ children, gap = 16, childMinWidth = 200 }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState<number>(0);

  const childWidth = useMemo(() => {
    if (containerRef.current) {
      return getChildWidth(containerRef.current.offsetWidth, childMinWidth, gap, children.length);
    }
    return childMinWidth;
  }, [parentWidth, children]);

  useEffect(() => {
    const updateParentWidth = () => {
      if (containerRef.current) {
        setParentWidth(containerRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateParentWidth();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      updateParentWidth();
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: `${gap}px`,
        width: '100%',
      }}
    >
      {children.map((child, index) => (
        <div
          key={index}
          style={{
            maxWidth: `${childWidth}px`,
            minWidth: `min(100%, ${childWidth}px)`,
            flexGrow: 1,
          }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

export default MultipleCardsContainer;
