import { FeedbackNotificationProps } from '@clarke-energia/foton';

import { IMessageComponent } from '@components/molecules/general/message-component';

export const CLARKE_WHATSAPP =
  'https://api.whatsapp.com/send?phone=5511974019971&text=Oi,%20estava%20na%20%C3%A1rea%20do%20cliente%20e%20gostaria%20de%20tirar%20uma%20d%C3%BAvida%0A%0A';

export const StateSymbols = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
  'DF',
];

export function convertNumberToMonth(month: string) {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const monthValue = parseInt(month);
  return months[monthValue - 1];
}

export const DocType = [
  { label: 'Contrato Clarke', value: 'CLARKE_CONTRACT' },
  { label: 'Contrato Fornecedor', value: 'SUPPLIER_CONTRACT' },
  { label: 'Procuração', value: 'POWER_OF_ATTORNEY' },
  { label: 'Termo de Gestão', value: 'MANAGEMENT_TERM' },
  { label: 'Fatura de Conta de Luz', value: 'ELECTRICITY_BILL_INVOICE' },
  { label: 'Termo de Pactuação', value: 'AGREEMENT_TERM' },
  { label: 'Contrato de Fornecimento de Energia', value: 'ENERGY_SUPPLY_CONTRACT' },
  { label: 'Carta Denúncia', value: 'LETTER_OF_COMPLAINT' },
  { label: 'Balanço Energético', value: 'ENERGY_BALANCE' },
  { label: 'Contrato de Uso de Sistema de Distribuição', value: 'DISTRIBUTION_SYSTEM_USE_CONTRACT' },
  { label: 'Documentos CCEE', value: 'CCEE_DOCUMENTS' },
  { label: 'Kit Bradesco', value: 'BRADESCO_KIT' },
];

export type ACLGroupMomentType = 'MIGRATION_IN_PROGRESS' | 'MIGRATION_CONCLUDED' | 'MIGRATION_NOT_STARTED';
export const InformationMessagesByMigrationMoment: Record<ACLGroupMomentType, IMessageComponent> = {
  MIGRATION_IN_PROGRESS: {
    title: 'Você ainda não está no mercado livre de energia.',
    description:
      'Está página está vazia porque a migração para o mercado livre ainda não foi concluída. Aqui ficarão os relatórios de economia que você receberá ao concluir quando estiver consumindo energia livremente.',
    mainButtonLabel: 'Acompanhar migração',
  },
  MIGRATION_CONCLUDED: {
    title: 'Você completou a migração para o mercado livre!',
    description:
      'Logo mais você verá o seu primeiro relatório de economia na plataforma. Ao final de cada mês disponibilizaremos o resultado da economia do mês anterior no mercado livre de energia.',
    mainButtonLabel: 'Tirar dúvidas',
  },
  MIGRATION_NOT_STARTED: {
    title: 'Você ainda não está no mercado livre de energia.',
    description:
      'Está página está vazia porque a migração para o mercado livre ainda não foi iniciada. Aqui ficarão os relatórios que você poderá acompanhar o seu status de migração para o mercado livre de energia.',
    mainButtonLabel: 'Tirar dúvidas',
  },
};

export type ConsumptionGroupMomentType = 'CONSUMPTION_NOT_STARTED' | 'CONSUMPTION_EMPTY';
export const InformationMessagesByConsumptionMoment: Record<ConsumptionGroupMomentType, IMessageComponent> = {
  CONSUMPTION_NOT_STARTED: {
    title: 'Por enquanto não é possível acompanhar o seu consumo.',
    description:
      'A dashboard de consumo estará disponível quando você tiver unidades consumidoras consumindo no Mercado Livre de Energia.',
    mainButtonLabel: 'Ver relatório de migração',
  },
  CONSUMPTION_EMPTY: {
    title: 'Não há dados sobre o mês escolhido.',
    description:
      'Por enquanto não há dados de consumo liberados sobre o mês escolhido. Assim que ele for disponibilizado na plataforma, traremos os gráficos aqui na Dashboard de Consumo.',
    mainButtonLabel: 'Ver mês anterior',
  },
};

export type CompiledResultType = 'COMPILED_RESULT_EMPTY';
export const InformationMessagesByCompilationResultsMoment: Record<CompiledResultType, IMessageComponent> = {
  COMPILED_RESULT_EMPTY: {
    title: 'Não ha nenhuma cotação de energia disponível no momento.',
    description: 'A cotação ficara disponível caso haja alguma em andamento.',
    mainButtonLabel: 'Voltar para Home.',
  },
};

export interface INotificationFeedbackContent {
  label: string;
  message: string;
  kind: FeedbackNotificationProps['kind'];
}

export const GENERAL_PANEL_PARAMS = 'painel-geral';
