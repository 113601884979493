import { NuggetInfo, NuggetInfoComponentProps as NuggetProps } from '@clarke-energia/foton-v2';

import style from './style.module.css';

const LargeNugget = (props: NuggetProps) => {
  return (
    <span className={style.nuggetContainer}>
      <NuggetInfo {...props} tooltip={undefined} className={style.nugget} />
    </span>
  );
};

export default LargeNugget;
