import groupBy from 'lodash.groupby';
import { useLazyQuery } from '@apollo/client';

import { formatMonthAndYearDate } from '@utils/text';
import { formatUnitsEconomyReports } from './parser';
import { QUERY_GET_UNITS_ECONOMY_REPORTS_BY_GROUP_ID } from './queries';
import { UnitEconomyReportDataResponse, UnitReportParsed } from './types';

export const useGetUnitEconomyByGroupId = () => {
  const [getUnitsEconomyReportsByGroupId, { data, loading, error }] = useLazyQuery(
    QUERY_GET_UNITS_ECONOMY_REPORTS_BY_GROUP_ID,
  );

  const parsedData: {
    rawHistoryUnitEconomyReport: UnitEconomyReportDataResponse[];
    unitsEconomyReports: Record<string, UnitReportParsed[]>;
  } = {
    rawHistoryUnitEconomyReport: [],
    unitsEconomyReports: {},
  };

  if (data) {
    const { unitsEconomyReportsByGroupId } = data;

    const filteredPublishedReports = (unitsEconomyReportsByGroupId as Array<UnitEconomyReportDataResponse>).filter(
      (report) => report.status === 'FINAL',
    );

    const reportsParsed = formatUnitsEconomyReports(filteredPublishedReports);

    parsedData.rawHistoryUnitEconomyReport = filteredPublishedReports;
    parsedData.unitsEconomyReports = groupBy(reportsParsed, (report) => formatMonthAndYearDate(report.date));
  }

  return {
    getUnitsEconomyReportsByGroupId,
    data: { ...parsedData },
    loading,
    error,
  };
};
