const configuration = {
  GATEWAY_API_HOST: import.meta.env.VITE_GATEWAY_API_HOST,
  DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
  IS_IN_PRODUCTION: import.meta.env.PROD,
  AUTH0_ACCESS_TOKEN: import.meta.env.VITE_AUTH0_ACCESS_TOKEN,
  REPORT_API_HOST: import.meta.env.VITE_REPORT_API_HOST,
};

if (!configuration.GATEWAY_API_HOST) {
  throw `Invalid GATEWAY_API_HOST! value found: ${configuration.GATEWAY_API_HOST}`;
} else if (!configuration.DOMAIN) {
  throw `Invalid DOMAIN! value found: ${configuration.DOMAIN}`;
} else if (!configuration.CLIENT_ID) {
  throw `Invalid CLIENT_ID! value found: ${configuration.CLIENT_ID}`;
}

export default configuration;
