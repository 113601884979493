import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '@components/templates/screen-layout';
import MultipleCardsContainer from '@components/atoms/layouts/multiple-cards-container';
import UnitProgress from '@components/organisms/migrations/migration-unit-progress-card';
import { MigrationStateNuggets } from '@components/organisms/migrations/migration-state-nuggets';
import { HubSkeleton } from '@components/molecules/skeleton/hub-skeleton';
import ExportButton from '@components/molecules/button/export-button';
import EmptyData from '@components/molecules/empty-data';

import { useUserInfo } from '@hooks/use-user-info';
import { useGetMigrationReportByGroup } from '@hooks/migration-report/get-migration-report-by-group';
import { MigrationState } from '@hooks/migration-report/types';
import { useIsMobile } from '@hooks/is-mobile';

import { formatDateFromString } from '@utils/dayjs';

import { HOME_PATH, MIGRATION_REPORT_PATH } from '@routers/constants';

import style from './style.module.css';

const MigrationHub = () => {
  const exportRef = useRef<HTMLDivElement>(null);

  const { user, isFetchingUser } = useUserInfo();
  const {
    getMigrationReportByGroupHandler,
    migrationReportByGroup,
    loading: loadingMigrationReports,
  } = useGetMigrationReportByGroup();

  const navigate = useNavigate();

  const isMobile = useIsMobile();

  useMemo(() => {
    if (user.group?.id) {
      getMigrationReportByGroupHandler(user.group.id);
    }
  }, [user]);

  if (loadingMigrationReports || isFetchingUser || !Object.keys(migrationReportByGroup).length) {
    return (
      <Layout.Content
        header={{
          title: isMobile ? 'Migração' : 'Migração para o Mercado Livre',
          displayDesktop: true,
        }}
      >
        <HubSkeleton />
      </Layout.Content>
    );
  }

  if (!migrationReportByGroup?.units) {
    return (
      <Layout.Content
        header={{
          title: isMobile ? 'Migração' : 'Migração para o Mercado Livre',
          displayDesktop: true,
        }}
      >
        <EmptyData description="A migração para o Mercado Livre ainda não foi iniciada. Você encontrará os relatórios de migração assim que iniciar o processo de migração" />
      </Layout.Content>
    );
  }

  return (
    <Layout.Content
      header={{
        title: isMobile ? 'Migração' : 'Migração para o Mercado Livre',
        displayDesktop: true,
        children: <ExportButton className="mr-2" exportRef={exportRef} exportName="relatorio-de-migração" />,
        breadCrumbLinks: [
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Migração para o Mercado Livre',
            url: MIGRATION_REPORT_PATH,
          },
        ],
      }}
    >
      <div className={style.container} ref={exportRef}>
        <MigrationStateNuggets
          migratedUnits={migrationReportByGroup?.units?.filter((unit) => unit.status === MigrationState.DONE).length}
          migratingUnits={
            migrationReportByGroup?.units?.filter((unit) => unit.status === MigrationState.IN_PROGRESS).length
          }
          overdueUnits={migrationReportByGroup?.units?.filter((unit) => unit.status === MigrationState.OVERDUE).length}
          onNuggetClick={(state: MigrationState) => {
            navigate(`${MIGRATION_REPORT_PATH}/status/${state}`);
          }}
        />

        <MultipleCardsContainer gap={32} childMinWidth={500}>
          {migrationReportByGroup?.units?.map((unit) => (
            <span key={unit.thirdPartyId} className={style.unitContainer}>
              <UnitProgress
                key={unit.thirdPartyId}
                state={unit.status}
                name={unit.nickname}
                groupName={migrationReportByGroup.commercialGroup}
                progress={unit.conclusionPercentage * 100}
                dueDate={unit.dueDate && formatDateFromString(unit.dueDate, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY')}
                stepsInfo={{
                  total: unit.topics.length,
                  completed: unit.topics.filter((topic) => topic.conclusionPercentage === 1).length,
                }}
                viewClick={() => {
                  navigate(`${MIGRATION_REPORT_PATH}/${unit.thirdPartyId}`);
                }}
              />
            </span>
          ))}
        </MultipleCardsContainer>
        {/* </div> */}
      </div>
    </Layout.Content>
  );
};

export default MigrationHub;
