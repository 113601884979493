export const colors = {
  primary: {
    0: '#00DF7C',
    1: '#19E58A',
    2: '#008C4C',
  },
  gray: {
    10: '#E8E8E8',
  },
  yellow: {
    20: '#FFD97A',
  },
};
