import { IconName, NuggetInfo } from '@clarke-energia/foton-v2';

import style from './style.module.css';

interface ListNugget {
  title: string;
  info: string;
  icon: IconName;
}

export interface UnitMigrationDocumentInfosProps {
  expectedDate: string | null;
  responsible: string;
  documents: number;
  pendencies: number;
  document: {
    names: string;
    description: string;
  };
}
const UnitMigrationDocumentInfos = (props: UnitMigrationDocumentInfosProps) => {
  const nuggetList: ListNugget[] = [
    {
      title: 'Data prevista',
      info: props.expectedDate || 'Não previsto',
      icon: 'CalendarDaysIcon',
    },
    {
      title: 'Responsável',
      info: props.responsible,
      icon: 'IdentificationIcon',
    },
    {
      title: 'Documentos necessários',
      info: props.documents.toString(),
      icon: 'DocumentDuplicateIcon',
    },
    {
      title: 'Pendências',
      info: props.pendencies.toString(),
      icon: 'ExclamationTriangleIcon',
    },
  ];

  return (
    <div className="py-5">
      <div className={style.container}>
        {nuggetList.map((item, index) => (
          <div className={style.nuggetContainer} key={index}>
            <NuggetInfo
              title={item.title}
              info={item.info}
              icon={{
                icon: item.icon,
              }}
              sizes={{
                title: 10,
                info: 12,
                contentSpace: 4,
                icon: 19,
              }}
              strongInfo
            />
          </div>
        ))}
      </div>
      <div className={style.textContentContainer}>
        <div>
          <p>Descrição</p>
          <p>{props.document.description}</p>
        </div>
        <div>
          <p>Documentos necessários</p>
          <p>{props.document.names}</p>
        </div>
      </div>
    </div>
  );
};

export default UnitMigrationDocumentInfos;
