import { ProgressBar } from '@clarke-energia/foton-v2';
import { twMerge } from 'tailwind-merge';

import style from './style.module.css';

const mapColors = {
  success: {
    bg: 'bg-[#E6F9DC]',
    bar: '#005931',
    text: 'text-[005931]',
    bgFocus: 'bg-[#55EC8F]',
    textFocus: 'text-gray-80',
  },
  warning: {
    bg: 'bg-[#FFD4CC]',
    bar: '#991700',
    text: 'text-[#991700] font-semibold',
    bgFocus: 'bg-orange-30',
    textFocus: 'text-light-20',
  },
  ongoing: {
    bg: 'bg-white',
    bar: '#2E2E2E',
    text: 'text-gray-80',
    bgFocus: 'bg-gray-10',
    textFocus: 'text-gray-80',
  },
  neutral: {
    bg: 'bg-white',
    bar: '#2E2E2E',
    text: 'text-gray-80',
    bgFocus: 'bg-gray-10',
    textFocus: 'text-gray-80',
  },
};

export interface StepProgressCardProps {
  step: number;
  description: string;
  status: 'success' | 'warning' | 'ongoing' | 'neutral';
  progress: number;
  focus?: boolean;
}

const StepProgressCard = (props: StepProgressCardProps) => {
  return (
    <div
      className={twMerge(mapColors[props.status].bg, style.container, props.focus && mapColors[props.status].bgFocus)}
    >
      <div
        className={twMerge(
          style.content,
          mapColors[props.status].text,
          props.focus && mapColors[props.status].textFocus,
        )}
      >
        <span>{props.step}.</span>
        <p>{props.description}</p>
      </div>
      <ProgressBar
        value={props.progress}
        label={{
          position: 'right',
          color: props.status === 'warning' && props.focus ? '#FAFAFA' : undefined,
        }}
        size="large"
        color={props.status === 'success' && props.focus ? '#222222' : mapColors[props.status].bar}
      />
    </div>
  );
};

export default StepProgressCard;
