import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuProps } from '@clarke-energia/foton-v2';

import { useUserInfo } from '@hooks/use-user-info';
import { useAuth0 } from '@auth0/auth0-react';

import { useMenuItemActive } from '@hooks/menu-item-active';
import { BASE_MENU_ITENS } from './helper';

import style from './sytle.module.css';

const Sidebar: React.FC = () => {
  const { user } = useUserInfo();
  const { logout } = useAuth0();

  const menuItems = BASE_MENU_ITENS.map((section) => ({
    ...section,
    links: section.links.map((item) => ({
      ...item,
      isActive: useMenuItemActive(item.path),
      LinkComponent: Link,
    })),
  })) as MenuProps['menuItem'];

  return (
    <aside className={style.container}>
      <Menu
        className="bg-white"
        handleLogout={logout}
        userInfo={{
          name: user.name,
          picture: { src: user?.photoUrl },
        }}
        menuItem={menuItems}
      />
    </aside>
  );
};

export default Sidebar;
